.custom-table-css {
    border-radius: 10px 10px 10px 10px;
    background-color: white;
    border: 1px solid #DCDCDC;
    padding: 16px;
    padding-bottom: 12px;

    .table-header {
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        text-align: left;
        color: #6F6B7D;
        border-bottom: 1px solid #DCDCDC;
        padding: 10px;
    }

    .red-notification {
        width: 30px;
        height: 30px;
        background-color: #FF0000;
        border-radius: 50%;
        padding: 1px;
        text-align: center;
        padding: 4px;
        color: #fff;
        font-weight: 600;
    }

    .table-body-tr {
        outline: 1px solid #DCDCDC;
        border-radius: 10px;
        gap: 5px;
        box-shadow: 0px 3px 12px 0px #00000012;
    }

    .whiteBtn {
        border: 1px solid #316AFF;
        border-radius: 6px;
        background-color: #FFFFFF;
        padding: 0 16px;
        margin-left: 8px;
        color: #316AFF;
        font-size: 14px;
        font-weight: 500;
        padding: 4px 15px;
    }

    .table-row {
        border-radius: 10px;
        font-size: 16px;
        font-weight: 500;
        height: 62px;
        letter-spacing: 0px;
        text-align: left;
        color: #6F6B7D;
    }

    .table-green-class {
        color: #43D200 !important;
        padding: 8px 16px;
        background-color: #D8FFC6;
        border-radius: 10px;
        text-align: center;
        font-weight: 500;
        font-size: 13px;
    }

    .table-red-class {
        color: #EA5455 !important;
        padding: 8px 16px;
        background-color: #FFDADA;
        border-radius: 10px;
        text-align: center;
        font-weight: 500;
        font-size: 13px;
    }

    .table-light-red-class {
        color: #f78383 !important;
        padding: 8px 16px;
        background-color: #f4909066;
        border-radius: 10px;
        text-align: center;
        font-weight: 500;
        font-size: 13px;
    }

    .table-yellow-class {
        color: #A2B000 !important;
        padding: 8px 16px;
        background-color: #FAFFC6;
        border-radius: 10px;
        text-align: center;
        font-weight: 500;
        font-size: 13px;
    }

    .table-dark-green-class {
        color: #2A8300;
        padding: 8px 16px;
        background-color: #BEFF9F;
        border-radius: 10px;
        text-align: center;
        font-weight: 500;
        font-size: 13px;
    }

    .table-body-tr td {
        padding: 10px;
    }

    .btnGreen {
        background-color: #28C76F29;
        // opacity: 16%;
        color: #28C76F;
        padding: 5px, 10px, 5px, 10px;
        border-radius: 4px;
        font-size: 13px;
        font-weight: 500;
        border: none;
        height: 30px;
        width: 113px
    }

    .btnPrimary {
        border-radius: 4px;
        gap: 10px;
        font-size: 13px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0px;
        border: none;
        height: 30px;
        width: 113px;
    }

    .btnRed {
        background-color: #EA545529;
        color: #EA5455;
        height: 30px;
        padding: 8px 10px;
        font-size: 13px;
        font-weight: 500;
        line-height: 14px;
        border: none;
        border-radius: 4px;
    }

    .form-check-input:focus {
        box-shadow: none;
    }

    .searchBar {
        background-color: #FFFFFF;
        height: 38px;
        outline: none;

        .searchTitle {
            font-size: 24px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0px;
            text-align: left;
            color: #101828;
        }

        .searchbox {
            font-size: 15px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: left;
            border: none;
        }

        .searchbox:focus-visible {
            outline: none;
        }

        ::placeholder {
            color: #B4B3B9;
        }

        .searchbar {
            border: 1px solid #DBDADE;
            border-radius: 6px;
            box-shadow: 0px 2px 12px 0px #0000001A;
            height: 38px;
        }
    }

    .small-primary-btn {
        border-radius: 6px;
        background: linear-gradient(254.93deg, #3B82F6 24.66%, #6FA6FF 72.12%);
        font-weight: 500px;
        font-size: 15px;
        color: #FFFFFF;
        border: none;
        padding: 6px 24px;
        width: 100%;
    }

    .table-small-text {
        font-size: 12px;
        color: #6F6B7D;
    }

    .green-text {
        color: #3DC154
    }

    .table-big-text{
        font-weight: 500;
        font-size: 14px;
        color:#101828;
    }

    .table-page-num {
        margin-bottom: 0px;
        font-size: 16px;
        font-weight: 600;
        color: #6F6B7D;
    }

    .table-page-num-text {
        margin-bottom: 2px;
        font-size: 16px;
        font-weight: 600;
        padding-left: 10px;
        padding-right: 10px;
        cursor: pointer;
        color: #C2C2C2;
    }
    .table-page-num-text-active {
        margin-bottom: 2px;
        font-size: 16px;
        font-weight: 600;
        padding-left: 10px;
        padding-right: 10px;
        cursor: pointer;
        color: #316AFF !important;
    }

    .table-page-num-text:hover {
        color: #0F75BD;
    }

    @media (max-width: 900px) {
        .custom-table-width-mobile {
            width: 740px;
        }
    }
}

.checkbox-css {
    height: 15px !important;
    width: 15px !important;
}

.blue-add-btn {
    border-radius: 6px;
    background: linear-gradient(254.93deg, #3B82F6 24.66%, #6FA6FF 72.12%);
    font-weight: 500px;
    font-size: 15px;
    color: #FFFFFF;
    border: none;
    padding: 6px 10px;
    width: 130px;
}

.another-table-css {
    border-radius:none !important;
    background-color: none !important;

    .table-small-text {
        font-size: 12px;
        color: #6F6B7D;
    }

    .green-text {
        color: #3DC154
    }
    .table-body-tr {
        outline: 1px solid #DCDCDC;
        border-radius: 10px;
        box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.0705882353);
    }
    .table-body-tr td {
        padding: 10px;
    }

    .table-big-text{
        font-weight: 500;
        font-size: 14px;
        color:#252525;
    }
}