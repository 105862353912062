.cardbox-css {

    .mainbox {
        display: flex;
        border: 1px solid rgba(220, 220, 220, 0.86);
        border-radius: 10px;
        background-color: #FFFFFF;
        justify-content: space-between;
        padding: 16px;
        box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.07);
    }

    .usericon {
        width: 58px !important;
        object-fit: contain;
        // height:46.4px
    }

    .box-title {
        font-weight: 500;
        font-size: 20px;
        color: #101828
    }

    .box-subheading {
        font-weight: 800;
        font-size: 24px;
        color: #316AFF
    }

}