@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: Poppins, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin-bottom: 0 ;
}

.blue-primary-btn {
  border-radius: 6px;
  background: linear-gradient(254.93deg, #3B82F6 24.66%, #6FA6FF 72.12%);
  font-weight: 500px;
  font-size: 15px;
  color: #FFFFFF;
  border: none;
  padding: 10px; 
}

.main-Page-css {
  padding: 18px;
}


.main-page-title {
  font-size: 32px;
  font-weight: 600;
  line-height: 37.6px;
  color: #6F6B7D;
  margin-bottom: 24px;
}