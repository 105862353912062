.investbox-css{
    .mainbox3 {
        align-items: center;
        border: 1px solid  #DBDADE;
        border-radius: 10px;
        background-color: #FFFFFF;
        justify-content: space-between;
        padding: 13px 18px;
        margin-bottom: 16px;
    }

    .text1{
        color: #3DC154 !important
    }

    .staricon{
        width:18px;
        object-fit: contain;
    }

    .boxheading{
        font-weight: 500;
        font-size: 15px !important;
        margin-left: 10px;
        color:#4B465C 
    }

}