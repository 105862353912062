.fundsBasket {

    .btn-group>.btn:not(:last-child):not(.dropdown-toggle),
    .btn-group>.btn.dropdown-toggle-split:first-child,
    .btn-group>.btn-group:not(:last-child)>.btn {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        width: 300px;
        text-align: start;
        background-color: #fff;
        color: #316AFF;
        border: 2px solid #316AFF;
    }

    .btn-group> :not(.btn-check:first-child)+.btn,
    .btn-group>.btn-group:not(:first-child) {
        margin-left: calc(var(--bs-border-width)* -1);
        background-color: #fff;
        color: #316AFF;
        border: 2px solid #316AFF;
    }

    .dropdown-menu.show {
        display: block;
        width: 330px;
        margin-top: 5px;
    }

    .dropdown-menu {
        padding: 16px;
        box-shadow: 0px 5px 30px 0px #00000033;
    }

    .dropdown-item:not(:last-child) {
        margin-bottom: 16px;
    }

    .dropdown-item:hover {
        background-color: #E4ECFF;
        // border: 1px #316AFF solid;
        border-color:none;
        color:#316AFF;
        border-radius: 6px;
    }

    .dropdown-item:active {
        background-color: #E4ECFF;
        border: 1px #316AFF solid;
        border-radius: 6px;
    }

    .dropdown-item {
        border: 1px #DCDCDC solid;
        border-radius: 6px;
    }

    .nav-link {
        color: #6F6B7D;
        text-decoration: none;
        transition: color 0.3s ease;
        font-weight: 600;
        background-color: white;
        border-radius: 7px 7px 0 0;
        align-items: center;
        height: 50px;
        width: 150px;
        text-align: center;
        padding: 10px !important;
        border-bottom: 3px solid #DCDCDC;

        // @media only screen and (max-width: 992px) {
        //     width: 165px;
        //     height: 80px;
        // }

        &:hover {
            color: #316AFF;
            border-bottom: 3px solid #316AFF;
        }

        &.active {
            color: #316AFF !important;
            background-color: #EAF0FF;
            border-radius: 7px 7px 0 0;
            border-bottom: 3px solid #316AFF;
        }

        // @media only screen and (max-width: 992px) {
        //     margin-bottom: 10px;
        // }
    }

    .nav-underline {
        --bs-nav-underline-gap: 0.70rem;
    }
}