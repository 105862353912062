.loader-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Add a semi-transparent background */
    z-index: 100000; /* Ensure it's on top of other elements */
    display: flex;
    align-items: center;
    justify-content: center;
}