.sidebar-scss {
    .sidebar-sticky {
        background-color: #FFFFFF;
        position: sticky;
        top: 0;
        // height: min-content;
        height: 100vh;
        overflow: hidden !important;
        flex: 1;
        position: fixed;
        width: inherit;
        justify-content: center;

        .menu-links::-webkit-scrollbar {
            display: none;
        }

        .leftside {
            padding-right: 0px;
        }

        @media only screen and (max-width: 992px) {
            position: fixed;
            z-index: 200;
            width: 100%;
            // padding-right: 1rem;
            height: 100%;
            overflow: auto;
        }

        .bars-desktop {
            @media only screen and (max-width: 992px) {
                display: none;
            }
        }

        .sidetext {
            margin-left: 10px;
            margin-right: 5px;
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            letter-spacing: 0em;
        }

        .nav-item {
            margin-left: 5px;
            margin-right: 5px;
            margin-top: 5px;
            margin-bottom: 10px;
            background-color: #E4ECFF;
            border-radius: 60px;

            &:hover {
                border-radius: 60px;
                background: linear-gradient(254.93deg, #3B82F6 24.66%, #6FA6FF 72.12%);
                color: #FFFFFF !important;
                box-shadow: 0px 4px 14px 0px #00000026;
                font-size: 16px;
                font-weight: 700;
                line-height: 19px;
                letter-spacing: 0em;
            }

            &:active {
                border-radius: 60px !important;
                background: linear-gradient(254.93deg, #3B82F6 24.66%, #6FA6FF 72.12%);
                color: #FFFFFF !important;
                box-shadow: 0px 4px 14px 0px #00000026;
                font-size: 16px;
                font-weight: 700;
                line-height: 19px;
                letter-spacing: 0em;
            }

            .active {
                border-radius: 60px !important;
                background: linear-gradient(254.93deg, #3B82F6 24.66%, #6FA6FF 72.12%);
                color: #FFFFFF !important;
                box-shadow: 0px 4px 14px 0px #00000026;
                font-size: 16px;
                font-weight: 700;
                line-height: 19px;
                letter-spacing: 0em;
            }
        }

        .nav-link {
            color: #0F75BD;
            display: flex;
            align-items: center;
            border-radius: 60px;
            font-size: 16px;
            font-weight: 600;
            line-height: 19px;
            letter-spacing: 0em;

            .siderbar-icons {
                width: 25px;
                object-fit: contain;
            }
        }

        .info-item {
            background-color: #FFFFFFBD !important;
            padding: 8px 16px;
            border-radius: 10px !important;
            box-shadow: 0px 4px 14px 0px #00000026;
            cursor: pointer;

            .siderbar-icons {
                width: 25px;
                object-fit: contain;
            }

            .sidebar-arrow-icon {
                width: 8.88px;
                object-fit: contain;
            }
        }

        .info-link {
            color: #0F75BD;
            font-size: 16px;
            font-weight: 600;
            line-height: 19px;
            letter-spacing: 0em;
            text-decoration: none;
            display: flex;
            align-items: center;
        }

        .Leftbar-logo {
            width: 70%;
            height: auto;
            object-fit: contain;
            margin: 20px 20px;

            @media only screen and (max-width: 992px) {
                width: 60%;
            }
        }
    }

    .bars {
        display: none;

        @media only screen and (max-width: 992px) {
            display: flex;
            position: absolute;
            top: 1rem;
            left: 60%;
            padding: 10px;
            border-radius: 10px;
            z-index: 300;
            // border: 1px solid #1570EF;
        }
    }

    .dropdown-menu {
        width: 100%;
    }

    .sidebar-scss {
        @media only screen and (max-width: 992px) {
            width: 0 !important;
        }
    }
}