.login {
    .logo-img {
        width: 120px;
        object-fit: contain;
    }

    .welcomeText {
        font-size: 26px;
        font-weight: 500;
        color: #4B465C;
        margin-bottom: 6px;
    }

    .verificationBox {
        height: 48px;
        border-radius: 8px;
        border: 1px solid #DBDADE;
        border-radius: 8px;

        @media only screen and (max-width: 500px) {
            margin-right: 0 !important;
        }
    }

    .verificationBox:hover {
        border-color: #316AFF;
    }

    .verificationBox:focus-visible {
        outline: none;
    }

    .form-label {
        font-size: 14px;
        font-weight: 600;
        margin: 0;
        margin-top: 16px;
        padding: 0;
        color: #6F6B7D;
    }

    .form-control {
        height: 38px;
        margin-top: 4px;
        font-size: 15px;
        padding: 7px, 14px, 7px, 14px;
        border-radius: 6px;
    }

    .form-control:focus {
        border: 1px solid #316AFF;
        box-shadow: none;
    }

    .text-lightgray {
        color: #B4B3B9
    }

    .imagecontainer {
        @media only screen and (max-width: 500px) {
            display: none !important;
        }
    }

    .login-form {
        @media only screen and (max-width: 500px) {
            padding: 32px !important;
        }

        @media only screen and (min-width: 1100px) {
            padding-right: 94px !important;
            padding-left: 62px !important;
        }

        ::placeholder {
            color: #B6B6B6;
        }
    }

    .verifyText {
        font-size: 15px;
        font-weight: 400;
        color: #4B465C;
        margin: 0;
    }

    .resendText {
        margin-top: 16px;
        font-size: 15px;
        font-weight: 400;
        color: #6F6B7D;
    }
}