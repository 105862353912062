.bluebox {
    border: 1px solid #316AFF;
    border-radius: 6px;
    background-color: #EFF6FF;
    padding: 6px 20px;
    cursor: pointer;
}

.backtext {
    font-size: 15px;
    color: #316AFF;
    font-weight: 500;
}

.searchbox {
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    border: none;
}

.searchbox:focus-visible {
    outline: none;
}

::placeholder {
    color: #B4B3B9;
    font-weight: 400 !important;
}

.searchbar {
    border: 1px solid #DBDADE !important;
    border-radius: 6px;
    box-shadow: 0px 2px 12px 0px #0000001A;
    height: 38px;
}

.modal-css {
    .modal-content {
        max-height: 500px;
        width: 800px;
    }

    .custom-table-css {
        min-height: auto;
        max-height: 365px;
        width: 765px;
        overflow-x: hidden;
        overflow-y: visible;
        // padding-bottom: 20px;
    }

    .custom-table-css::-webkit-scrollbar {
        display: none;
    }

    .custom-table-css {
        scrollbar-width: none;
    }

}