.userlist-css{

    .labelhead{
        font-weight: 400;
        font-size: 13px;
        color:#4A4755;
    }

    .inputbox{
        border:1px solid #DBDADE;
        border-radius: 6px;
        padding: 7px 14px;
        width:100%
    }

    .borderline{
        margin-top: 24px;
        margin-bottom: 24px;
        border:1px solid rgba(220, 220, 220, 0.86);
    }

    .mainbox{
        border-radius: 6px;
        padding: 16px;
        background-color:  #FFFFFF;
        border:1px solid #DBDADE
    }

    .investhead{
        font-weight: 500;
        font-size: 15px;
        color: #4B465C
    }

    .box10{
        width: 7%;
    }

}