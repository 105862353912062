.investmentcard-css {

    .mainbox1 {
        align-items: center;
        border: 1px solid #E5E5E5;
        border-radius: 10px;
        background-color: #FFFFFF;
        justify-content: space-between;
        padding: 10px;
        width:100%;
        margin-bottom: 16px;
    }

    .boxheading{
        font-weight: 500;
        font-size: 16px !important;
        margin-left: 4px;
    }

    .bankicon {
        width: 28.6px !important;
        object-fit: contain;
        // height:46.4px
    }

    .boxtitle{
        font-weight: 400;
        font-size: 16;
        color: #636366
    }

    .boxsubheading{
        font-weight: 500;
        font-size: 14;
        color:#101828;
        text-align: center;
    }

    .box1{
        border-bottom: 1px solid #EDEDED;
        margin-bottom: 2px;
    }

    .box2{
        display: flex;
        justify-content: space-between;
    }

}